<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 15:29:17
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-07 16:36:25
-->
<template>
  <div>
    <div class="platform-details">
      <div class="title">{{ title }}</div>
      <div class="desc" v-if="type == 1" v-html="data.registrationProcess">
        {{ data.registrationProcess }}
      </div>
      <div class="desc" v-if="type == 2" v-html="data.loginProcess">
        {{ data.loginProcess }}
      </div>
      <div class="desc" v-if="type == 3" v-html="data.orderProcess">
        {{ data.orderProcess }}
      </div>
      <div class="desc" v-if="type == 4" v-html="data.withdrawalProcess">
        {{ data.withdrawalProcess }}
      </div>
      <div class="desc" v-if="type == 5" v-html="data.earningsProcess">
        {{ data.earningsProcess }}
      </div>
      <div class="desc" v-if="type == 6" v-html="data.terminalProcess">
        {{ data.terminalProcess }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 1,
      title: "注册流程",
      data: {}
    };
  },
  mounted() {
    let type = this.$route.query.type;
    this.type = type;
    if (type == 1) {
      this.title = "注册流程";
    } else if (type == 2) {
      this.title = "多端登陆流程";
    } else if (type == 3) {
      this.title = "接单流程";
    } else if (type == 4) {
      this.title = "提现流程";
    } else if (type == 5) {
      this.title = "收益情况";
    } else if (type == 6) {
      this.title = "移动端门户网站";
    }
    this.getUserGuide(type);
  },
  methods: {
    getUserGuide(type) {
      this.$api
        .getUserGuide({ type })
        .then(res => {
          this.data = res.data;
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally();
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
